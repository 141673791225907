<template>
  <el-dialog title="编辑参数" :visible.sync="editVisible" width="600px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="form-body" v-if="flag!=''">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item :label="paramItem.param.chinese" prop="value">
          <div v-if="flag=='select'">
            <el-select v-model="ruleForm.value" placeholder="请选择设置值" style="width:100%;">
              <el-option v-for="(item,index) in itemList" :key="index" :label="item.name" :value="item.value"/>
            </el-select>
          </div>
          <div v-else>
            <el-input v-model="ruleForm.value" style="width:120px;margin-right:5px;"/>（{{ paramItem.param.fanwei }}）
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        value: ""
      },
      rules: {
        value: [
          {
            required: true,
            message: "请设置更新值",
            trigger: "blur",
          }
        ]
      },
      itemList: [],
      flag: ""
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["editVisible","paramItem","deviceId"],
  watch: {
    editVisible(newValue) {
      if (newValue) {
        this.itemList=[];
        if (this.paramItem.param.stype="int") {
          this.ruleForm.value=parseInt(this.paramItem.value);
        } else if (this.paramItem.param.stype="double") {
          this.ruleForm.value=parseFloat(this.paramItem.value);
        } else {
          this.ruleForm.value=this.paramItem.value;
        }
        let fanwei=this.paramItem.param.fanwei;
        if (fanwei==""||fanwei==null||fanwei==undefined) {
          this.flag="input";
        } else {
          if (fanwei.includes(":")) {
            let array=fanwei.split(";");
            for (var i=0; i<array.length; i++) {
              let data=array[i].split(":");
              this.itemList.push({
                "name":data[1],
                "value":parseInt(data[0])
              });
            } 
            this.flag="select";
          } else {
            this.flag="input";
          }
        }
      }
    },
  },
  methods: {
    onInput() {
      this.$forceUpdate();
    },
    handleClose() {
      this.ruleForm = {
        value: null,
      };
      this.$emit("handleClose");
    },
    submit() {
      if (this.ruleForm.value==null||(""+this.ruleForm.value)=="") {
        this.$message.error("请设置参数值");
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            var param={
              "deviceId": this.deviceId,
              "name": this.paramItem.name,
              "value": this.ruleForm.value
            };
            this.request.deviceSetParams(param).then((res) => {
              if (res.code==0) {
                this.$message.success("修改成功");
                this.$emit("handleClose",param);
              }
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.form-body {
  width:550px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  box-sizing:border-box;
}
</style>