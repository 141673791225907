const compareList = [
  {
    label: "大于",
    value: "gt",
  },
  {
    label: "小于",
    value: "lt",
  },
  {
    label: "大于等于",
    value: "ge",
  },
  {
    label: "小于等于",
    value: "le",
  },
  {
    label: "等于",
    value: "eq",
  },
  {
    label: "不等于",
    value: "ne",
  },
]

export default compareList;