<template>
  <el-dialog title="项目二维码" :visible.sync="viewVisible" width="500px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div  v-print="printConfig" >
      <div id="printContent" style="display:flex;justify-content:center;flex-direction:column;">
        <div style="text-align:center;margin-left:auto;margin-right:auto;font-size:18px;margin-top:20px;">{{ projectName }}</div>
        <div style="text-align:center;margin-left:auto;margin-right:auto;font-size:18px;margin-top:10px;">微信搜索 <span style="font-weight:bold;font-size:18px;">智慧能源小助手</span> 扫码关注</div>
        <canvas ref="qrcodeCanvas" style="margin-left:25px;"/>
      </div>
      <button v-print="printConfig" class="button-print">打印项目二维码</button>
    </div>
  </el-dialog>
</template>

<script lang="js">
import QRCode from 'qrcode';

export default {
  name: 'FormTitle',
  props: ["viewVisible","projectCode","projectName"],
  data() {
    return {
      printConfig: {
        id: 'printContent',
        popTitle: '项目二维码',
        previewBeforeOpenCallback() {},
        previewOpenCallback() {},
        beforeOpenCallback(vue) {
          vue.printLoading = true;
        },
        openCallback(vue) {
          vue.printLoading = false;
        },
        closeCallback() {
          location.reload();
        },
        clickMounted(vue) {},
      }
    }
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        setTimeout(()=>{
          this.generateQRCode();
        },1000);
      }
    },
  },
  methods: {
    generateQRCode() {
      const canvas = this.$refs.qrcodeCanvas;
      QRCode.toCanvas(canvas, this.projectCode, {width:400,height:400}, error => {if (error) console.error(error)});
    },
    handleClose() {
      this.$emit("handleClose");
    }
  }
}

</script>
<style scoped lang="scss">
  .button-print {
    margin-left:75px;
    background-color:#326fff;
    color:white;
    font-size:18px;
    text-align:center;
    width:300px;
    height:42px;
    border-radius:5px;
    line-height:42px;
    margin-top:20px;
    border:none;
  }
</style>