<template>
  <div class="pro-info">
    <div class="fixed-project">> {{ projectInfo.projectName }}</div>
    <div class="left-data">
      <div class="device">
        <div style="margin-bottom:10px;font-size:14px;font-weight:bold;">数据列表</div>
        <div class="device-tree">
          <div class="tree">
            <el-tree ref="tree" :data="deviceList" node-key="id" :props="deviceProps" @node-click="treeClick" :expand-on-click-node="false">
              <span class="custom-tree-node show-hide" slot-scope="{ node, data }">
                <div class="online" v-if="data.online=='yes'">
                  <template v-if="data.nodeList"><span style="font-weight: bold;"> {{ data.nick }} </span> <span style="font-size:11px;font-weight: bold;">{{ subStr(data.gateway) }}</span></template>
                  <template v-else>
                    <template v-if="node.isCurrent==true">
                      <span style="color:blue"> {{ node.label }} </span>
                    </template>
                    <template v-else>
                      <span style="color:black"> {{ node.label }} </span>
                    </template>
                  </template>
                </div>
                <div v-else class="offline">
                  <span>{{ data.nick }} </span> <span v-if="data.nodeList" style="font-size:12px;">{{ subStr(data.gateway) }}</span>
                </div>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="currentDevice!=null&&currentGroup==null">
        <div class="device-info">
          <div v-if="currentDevice.port!=null" class="row-item">
            <div class="param-item">
              设备编号：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.deviceId }}</span>
            </div>
            <div class="param-item">
              通讯协议：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.ptype }}</span>
            </div>
            <div class="param-item">
              通讯端口：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.port }}</span>
            </div>
            <div class="param-item">
              网络IP地址：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.ip==null?'无':currentDevice.ip }}</span>
            </div>
          </div>
          <div class="row-item">
            <div class="param-item">
              设备名称：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.nick }}</span>
            </div>
            <div class="param-item">
              是否在线：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.online=='yes'?'是':'否' }}</span>
            </div>
            <div class="param-item">
              从机地址：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.address }}</span>
            </div>
            <div class="param-item">
              通讯波特率：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.baudRate }}</span>
            </div>
          </div>
          <div class="row-item">
            <div class="param-item">
              设备类型：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.categoryName }}</span>
            </div>
            <div class="param-item">
              设备型号：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.modelName }}</span>
            </div>
            <div class="param-item">
              制造厂商：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.manufacturerName }}</span>
            </div>
            <div class="param-item">
              {{ currentDevice.port!=null?'边缘服务器：':'网关编号：' }}<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.gateway }}</span>
            </div>
          </div>
          <div class="row-item">
            <div class="param-item">
              解码协议：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.codec }}</span>
            </div>
            <div class="param-item">
              奇偶校验：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.parityCheck }}</span>
            </div>
            <div class="param-item">
              数据位：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.dataBits }}</span>
            </div>
            <div class="param-item">
              停止位：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.stopBits }}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="currentDevice==null" style="font-size:14px;margin-top:20px;margin-left:20px;">还未绑定设备</div>
      </template>
      <div class="device-chart" v-if="currentGroup!=null">
        <div class="param-setting">
          <div class="param-title">
            {{ currentDevice.name + ' - ' + currentGroup}}
            <el-select v-model="chartType" size="small" style="width:120px;margin-left:10px;" @change="changeChart">
              <el-option label="单参曲线" :value=10 />
              <el-option label="聚合曲线" :value=20 />
            </el-select>
          </div>
          <div class="param-date">
            <el-date-picker class="pick-date" style="width:140px;" v-model="selectDate" type="date" placeholder="选择日期" :picker-options="pickerOptions" @change="changeDate" />
          </div>
        </div>
        <template v-if="chartType==10">
          <template v-for="(item, index) in deviceGroup[currentGroup]">
            <div class="row-chart" v-if="index%2==0" :key="index">
              <div class="item-chart" :id="'echart-'+item.id"/>
              <div class="item-chart" v-if="deviceGroup[currentGroup][index+1]!=null" :id="'echart-'+deviceGroup[currentGroup][index+1].id"/>
            </div>
          </template>
          <div style="width:100%;height:1px;background-color:whitesmoke;"/>
        </template>
        <template v-else>
          <div class="group-row">
            <div class="group-chart">
              <div class="param-list" v-if="paramList.length>0">
                <el-checkbox-group v-model="checkParams" @change="handleChecked">
                  <el-checkbox v-for="(item, index) in deviceGroup[currentGroup]" :key="index" :label="item.id" class="check-item">{{ item!=null?item.chinese:''}}</el-checkbox> 
                </el-checkbox-group>
              </div>
              <div id="group-chart" style="width:100%;height:550px;"/>
            </div>
          </div>  
        </template>
      </div>
    </div>  
  </div>
</template>

<script>
import { DateToYYYYYMMDD, DateToHHmm } from "../../../utils/util/util";
import * as echarts from "echarts";
import { mapState } from "vuex";
export default {
  data() {
    return {
      iconFilter: require("./images/icon_filter.png"),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      deviceProps: {
        children: "nodeList",
        label: "name"
      },
      chartType: 10, //10：单参曲线，20：聚合曲线
      initChart: false,
      bakupChart: {},
      selectDate: "",
      currentGroup: null,
      currentDevice: null,
      dataTime: "",  
      projectInfo: {},
      deviceList: [],
      paramList: [],
      currentData: [],
      deviceGroup: {},
      dataGroup: {},
      chartList: [],
      checkParams: [],
      groupOption: {},
      groupChart: null,
    };
  },
  components: {},
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  mounted() {
    this.projectInfo=this.projectCurrentRowData;
    this.selectDate=new Date();
    this.getDeviceGroup();
  },
  methods: {
    changeChart() {
      if (this.chartType==20) {
        setTimeout(()=>{
          this.initGroupChart();
        },500);
      } else if (this.chartType==10) {
        setTimeout(()=>{
          this.showCharts();
        },500);
      }
    },
    changeDate() {
      if (this.chartType==20) {
        this.handleChecked(null);
      } else {
        this.dataGroup=[];
        this.showGroupList(this.currentDevice.deviceId,this.currentGroup);
      }
    },
    handleChecked(val) {
      if (val!=null) {
        this.checkParams=val;
      }
      this.clearChart();
      if (this.checkParams.length>0) {
        for (var i=0;i<this.checkParams.length;i++) { 
          var serie=this.bakupChart[this.checkParams[i]+"-"+DateToYYYYYMMDD(this.selectDate)];
          if (serie!=null) {
            this.groupOption.series.push(this.copySerie(serie));    
          } else {
            //从服务器获取
            for (var j=0;j<this.paramList.length;j++) {
              if (this.checkParams[i]==this.paramList[j].id) {
                this.getGroupChartData(this.paramList[j]);
              }
            }
          }
        }
        this.groupChart.setOption(this.groupOption,true);
      }
    },
    getGroupChartData(item) {
      var params={
        "deviceId": this.currentDevice.deviceId,
        "items": item.name,
        "date": DateToYYYYYMMDD(this.selectDate)
      };
      this.request.readDateParams(params).then((res) => {
        if (res.code == 0) {
          var data=[];
          if (res.data!=null&&res.data.length>0) {
            let list=res.data[0].data;
            if (list!=null&&list.length>0) {
              for (let i=0; i<list.length; i++) {
                var time=DateToHHmm(new Date(parseInt(list[i].time)*1000));
                data.push({"value":[time,list[i].value]});
              }
            }
          }
          var serie={
            "data": data,
            "type": "line",
            "name": item!=null?(item.chinese+(item.unit!=null?item.unit:'')):'',
            "smooth": true,
            "endLabel": {
              "show": true,
              "formatter": function (params) {
                return params.seriesName;
              }
            }
          };
          this.bakupChart[item.id+"-"+DateToYYYYYMMDD(this.selectDate)]=serie;
          this.groupOption.series.push(this.copySerie(serie));
          this.groupChart.setOption(this.groupOption,true);
        }
      });
    },
    copySerie(serie) {
      return {
        "data": serie.data,
        "name": serie.name,
        "type": "line",
        "smooth": true,
      };
    },
    clearChart() {
      if (this.groupOption.series!=null) {
        for (var i = 0; i < this.groupOption.series.length; i++) {
          this.groupOption.series[i].data = [];
          this.groupOption.series[i].name = '';  
        }
        this.groupOption.series.splice(0,this.groupOption.series.length);
        this.groupChart.setOption(this.groupOption,true);
      }
    },
    initGroupChart() {
      var series=[];
      var chartDom = document.getElementById("group-chart");
      this.groupChart = echarts.init(chartDom,'light');
      this.groupOption = {
        xAxis: {
          type: "category",
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
        },
        legend: {
          show: true,
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: 40,
          right: 20,
          top: 40,
          bottom: 30,
        },
        series: series,
      };
      this.groupChart.setOption(this.groupOption,true);
      this.initChart=true;
    },
    getDeviceGroup() {
      var params={
        "project":this.projectInfo.projectCode,
        "filterFlag":"chart"
      };
      this.request.deviceGroup(params).then((res) => {
        if (res.code == 0) {
          this.deviceList=res.data;
          this.currentDevice=this.deviceList[0];
          this.getParamList();
        }
      });
    },
    treeClick(data) {
      if (data.categoryName!=null) {
        this.currentDevice=data;
        this.currentGroup=null;
      } else {
        this.showGroupList(data.deviceId,data.name);
      }
    },
    showGroupList(deviceId, itemName) {
      for (var i=0; i<this.chartList.length; i++) {
        this.chartList[i].dispose();
      }
      this.chartList=[];
      if (deviceId!=this.currentDevice.deviceId) {
        this.deviceGroup={};
        if (this.chartType==20) {
          setTimeout(()=>{
            this.initGroupChart();
          },500);
        }
        for (var i=0; i<this.deviceList.length; i++) {
          if (deviceId==this.deviceList[i].deviceId) {
            this.currentDevice=this.deviceList[i];
            this.currentGroup=itemName;
            this.getParamList();
            break;
          }
        }
      } else {
        this.currentGroup=itemName;
        if (this.chartType==10) {
          setTimeout(()=>{
            this.showCharts();
          },500);
        } else {
          this.checkParams=[];
          this.clearChart();
        }
      }
    },
    getParamList() {
      var params={
        "deviceId":this.currentDevice.deviceId
      }
      this.request.deviceParams(params).then((res) => {
        if (res.code == 0) {
          this.paramList=res.data;
          for (var i=0; i<this.paramList.length; i++) {
            if (this.paramList[i].groupName==null||this.paramList[i].groupName=='') {
              this.paramList[i].groupName="未分类";
            }
          }
          for (var i=0; i<this.paramList.length; i++) {
            if (this.deviceGroup[this.paramList[i].groupName]==null) {
              this.deviceGroup[this.paramList[i].groupName]=[];
            }
            if (this.paramList[i].chartFlag==10) {
              this.deviceGroup[this.paramList[i].groupName].push(this.paramList[i]);
            }
          }
          for (var key in this.deviceGroup) {
            if (this.deviceGroup[key].length==0) {
              delete this.deviceGroup[key];
              for (var i=0; i<this.currentDevice.nodeList.length; i++) {
                if (this.currentDevice.nodeList[i].name==key) {
                  this.currentDevice.nodeList.splice(i,1);
                  i--;
                }
              }
            }
          }
          if (this.currentGroup!=null) {
            if (this.deviceGroup[this.currentGroup]==null||this.deviceGroup[this.currentGroup]==undefined) {
              for (var key in this.deviceGroup) {
                this.currentGroup=key;
                break;
              }
            }
            this.$forceUpdate();
            if (this.chartType==10) {
              setTimeout(()=>{
                this.showCharts();
              },1000);
            } else {
              this.checkParams=[];
              this.clearChart();
            }
          }
        }
      });
    },
    getParamData(item) {
      var params={
        "deviceId": this.currentDevice.deviceId,
        "items": item.name,
        "date": DateToYYYYYMMDD(this.selectDate)
      };
      this.request.readDateParams(params).then((res) => {
        if (res.code == 0) {
          if (res.data!=null&&res.data.length>0) {
            var data=[];
            let list=res.data[0].data;
            if (list!=null&&list.length>0) {
              for (let i=0; i<list.length; i++) {
                var time=DateToHHmm(new Date(parseInt(list[i].time)*1000));
                data.push({"value":[time,list[i].value]});
              }
            }
            this.dataGroup['echart-'+this.currentDevice.deviceId+'-'+item.id]=data;
            this.showChart(item);
          }
        }
      });
    },
    showCharts() {
      if (this.currentGroup!=null) {
        for (var i=0; i<this.deviceGroup[this.currentGroup].length; i++) {
          let item=this.deviceGroup[this.currentGroup][i];
          if (this.dataGroup['echart-'+this.currentDevice.deviceId+'-'+item.id]==null) {
            this.getParamData(item);
          } else {
            this.showChart(item);
          }
        }
      }
    },
    showChart(item) {
      const that=this;
      var chartDom = document.getElementById("echart-"+item.id);
      if (chartDom!=null) {
        var myChart = echarts.init(chartDom,'light');
        var option = {
          title: {
            text: item.chinese+(item.unit!=null?item.unit:''),
            textStyle: {
              fontSize: 15,
              fontStyle: "normal",
              fontWeight: "normal", 
            }
          },
          toolbox: {
            feature: {
              myTool1: {
                show: true,
                title: '导出数据',
                icon: 'path://M18 20.288L21.288 17l-.688-.688l-2.1 2.1v-4.887h-1v4.887l-2.1-2.1l-.688.688zM14.5 23.5v-1h7v1zm-10-4v-17H13L18.5 8v3.14h-1V8.5h-5v-5h-7v15h6.615v1zm1-1v-15z',
                onclick: function () {
                  setTimeout(() => {
                    let dataList=that.dataGroup['echart-'+that.currentDevice.deviceId+'-'+item.id];
                    const csv = convertToCSV(dataList);
                    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'data'+new Date().getTime()+'.csv';
                    link.click();
                    function convertToCSV(data) {
                      let csv = 'time,data\n';
                      for (var i=0; i<data.length; i++) {
                        csv += data[i].value[0] + ',' + data[i].value[1] + '\n';
                      }
                      return csv;
                    }
                  }, 10);
                },
              },
            },
          },
          xAxis: {
            type: "category",
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: 40,
            right: 20,
            top: 40,
            bottom: 30,
          },
          series: [
            {
              data: this.dataGroup['echart-'+this.currentDevice.deviceId+'-'+item.id],
              type: "line",
              smooth: true,
              showSymbol: false,
            }
          ],
        };
        myChart.setOption(option,true);
        this.chartList.push(myChart);
      } else {
        //console.log(item);
      }
    },
    subStr(gateway) {
      if (gateway==null||gateway=='') {
        return "";
      } else {
        if (gateway.length<=4) {
          return gateway;
        } else {
          return " ("+gateway.substr(gateway.length-4,4)+")";
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.fixed-project {
  position: fixed; 
  top: 70px; 
  left: 420px;
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
  color: black;
  text-align: left;
  font-size: small;
  z-index: 999;
}
.device-info {
  width: 94%;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
  padding-left: 4%;
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.row-item{
  display: flex;
  flex-direction: row;
  width:100%;
  margin-top: 10px;
}
.param-item {
  width: 25%;
  font-size:14px;
  height:20px;
  overflow-x: auto;
  overflow-y: hidden;
}
::-webkit-scrollbar {
    display: none;
}
.content {
  width: 83%;
  height: 100%;
  background-color: whitesmoke;
  overflow: auto;
}
.online {
  color: black
}
.offline {
  color: black
}
.left-data {
  width: 17%;
  height: 99%;
  margin-bottom: 1%;
}
.device {
  height: 100%;
  padding-left: 10px;
  background: #ffffff;
  overflow: auto;
}
.pro-info {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
.el-tree ::v-deep.el-tree-node>.el-tree-node__content {
    height: 40px;
}
.show-hide:hover :nth-child(2) {
    display: inline-block !important;
}
.device-tree {
  width: 96%;
  height: 96%;
  padding: 1%;
  margin: 1%;
  box-sizing: border-box;
  flex-shrink: 0;
}
.device-chart {
  width: 98%;
  height: 98%;
  padding: 1%;
  background-color: whitesmoke;
}
.row-chart {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 230px;
  margin-bottom: 10px;
  background-color: white;
}
.item-chart {
  width: 48%;
  margin-left: 1%;
  margin-right: 1%;
  height: 210px;
  padding-top: 5px;
  margin-top: 15px;
}
.pick-date {
  ::v-deep .el-input__inner{
			height:30px;
      line-height:30px;
	}
  ::v-deep .el-input__icon {
    height:30px;
    line-height:30px;
  }
}
.param-setting {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: azure;
}
.param-title {
  font-size: 16px;
  width: 49%;
  padding-left: 1%;
  text-align: left;
}
.param-date {
  width: 49%;
  padding-right: 1%;
  text-align: right;
}
.check-item {
  width: 21%;
  height: 25px;
}
.param-list {
  width: 96%;
  height: 90px;
  margin: 1%;
  padding: 1%;
  background-color: whitesmoke;
  border-radius: 5px;
  overflow: auto;
}
.group-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 93%;
}
.group-chart {
  width: 100%;
  height: 100%;
  background-color: white;
}
</style>
