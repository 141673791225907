<template>
  <el-dialog title="一键下发设定" :visible.sync="onekeyVisible" width="800px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="form-body">
      <el-form :model="ruleForm" ref="ruleForm" :show-message="false">
        <div class="box">
          <el-form-item prop="weekDays" label="下发日期（周一 到 周日）">
            <el-input @input="onInput()" placeholder="1,2,3,4,5,6,7" style="width:650px;" v-model="ruleForm.weekDays"/>
          </el-form-item>
          <el-form-item prop="trigerTime" label="下发时间（时:分）">
            <el-input @input="onInput()" placeholder="08:30" style="width:650px;" v-model="ruleForm.trigerTime"/>
          </el-form-item>
        </div>
        <el-button @click="addAction" size="mini" style="margin-bottom:10px;color:#3456ff;">添加下发设备</el-button>
        <div class="box">
          <div class="box-container">
            <div class="box-scroll">
              <div class="box-label-container" v-for="(action, index1) in actions" :key="index1">
                <p class="value" style="width:30%;">
                  <el-form-item
                    :prop="action.key + '_deviceId'"
                    :rules="[{required: true,message: '请选择设备',trigger: 'change',},]">
                    <el-select
                      @input="onInput()"
                      size="mini"
                      placeholder="请选择设备"
                      style="width:100%"
                      v-model="ruleForm[action.key + '_deviceId']"
                      @change="changeWriteConditionDevice(ruleForm[action.key+'_deviceId'],index1)">
                      <el-option
                        v-for="(item, index2) in deviceList"
                        :key="action.key + '_device_' + index2 + '_' + item.id"
                        style="width:100%"
                        :label="item.categoryName"
                        :value="item.id"/>
                    </el-select>
                  </el-form-item>
                </p>
                <p class="value" style="width:30%;">
                  <el-form-item
                    :prop="action.key + '_attr'"
                    :rules="[{required: true,message: '请选择状态',trigger: 'change',},]">
                    <el-select
                      @input="onInput()"
                      size="mini"
                      placeholder="请选择状态"
                      style="width:100%"
                      v-model="ruleForm[action.key + '_attr']"
                      @change="changeActionAttr(ruleForm[action.key + '_attr'],index1)">
                      <el-option
                        v-for="(item, index2) in deviceWriteConditionList[index1]"
                        style="width:100%"
                        :key="action.key + '_attr_' + index2 + '_' + item.value"
                        :label="item.name"
                        :value="item.id"/>
                    </el-select>
                  </el-form-item>
                </p>
                <p class="value" style="width:30%;">
                  <el-form-item
                    :prop="action.key + '_value'"
                    :rules="[{required: true,message: '请设定数值',trigger: 'change',},]">
                    <template v-if="actionValueList[index1]==null||actionValueList[index1].length==0">
                      <el-input
                        @input="onInput()"
                        size="mini"
                        placeholder="设定值"
                        style="width:100%"
                        v-model="ruleForm[action.key + '_value']"/>
                    </template>
                    <template v-if="actionValueList[index1]!=null&&actionValueList[index1].length>0">
                      <el-select
                        @input="onInput()"
                        size="mini"
                        placeholder="请选择"
                        style="width:100%"
                        v-model="ruleForm[action.key + '_value']">
                        <el-option v-for="(item, index2) in actionValueList[index1]" 
                          :key="action.key + '_value' + index2 + '_' + item.value"
                          :label="item.label"
                          :value="item.value"/>
                      </el-select>
                    </template> 
                  </el-form-item>
                </p>
                <p @click="deleteAction(index1, action)" style="width:5%;">
                  &nbsp;&nbsp;<i class="el-icon-delete"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style="display:flex;margin-top:10px;margin-bottom:10px;">
          <el-button @click="handleClose" size="mini" style="margin-top:10px;margin-right:10px;">取 消</el-button>
          <el-button type="primary" @click="submit" size="mini" style="margin-top:10px;margin-right:10px;">确 定</el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        project: this.project,
        name: "一键下发",
        weekDays: "1,2,3,4,5,6,7",
        trigerTime: "08:30"
      },
      actions: [],
      ruleList: [],
      deviceList: [], 
      deviceParamsList: [], 
      deviceWriteConditionList: [], 
      actionValueList: [],    // 触发动作参数值范围
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["onekeyVisible","project"],
  watch: {
    onekeyVisible(newValue) {
      if (newValue) {
        this.reset();
        this.getDeviceList();
        this.getRules();
      }
    },
  },
  methods: {
    onInput() {
      this.$forceUpdate();
    },
    handleClose() {
      this.$emit("viewClose");
    },
    reset() {
      const key = new Date().getTime();
      this.ruleForm = {
        id: null,
        project: this.project,
        name: "一键下发",
        weekDays: "1,2,3,4,5,6,7",
        trigerTime: "08:30"
      };
      this.setFormValue("act_" + key + "_deviceId", "");
      this.setFormValue("act_" + key + "_attr", "");
      this.setFormValue("act_" + key + "_value", "");
      this.actions = [
        {
          deviceId: "",
          attr: "",
          value: "",
          key: "act_" + key,
        },
      ];
      this.deviceConditionList = [];
    },
    getRules() {
      this.request.listRule({
        project: this.project,
      }).then((res) => {
        this.ruleList = res.data || [];
        if (this.ruleList.length>0) {
          for (var i=0; i<this.ruleList.length; i++) {
            if (this.ruleList[i].ruleType==20) {
              const item=this.ruleList[i];
              const that=this;
              setTimeout(function() {
                that.getDetailInfo(item);
              },1000);
              return;
            }
          }
        }
      });
    },
    getDeviceList() {
      this.request.deviceList({ project: this.project }).then((res) => {
        this.deviceList = res.data || [];
      });
    },
    getDeviceParams(deviceId, index, flag) {
      this.request.deviceScreenInfo({
        deviceId,
        project: this.project,
      }).then((res) => {
        this.deviceParamsList = (res.data || {}).dictParams || [];
        if (flag === 1) {
          // readWrite 10：可读，30：可读可写 并且 userFlag 10：用户可查看
          this.deviceReadConditionList[index] = this.deviceParamsList.filter(
            (item) => (item.readWrite == 10 || item.readWrite == 30) && item.userFlag == 10
          );
          this.deviceReadConditionList.splice(0, 0);
        }
        if (flag === 2) {
          // readWrite 20：可写 30：可读可写 并且 userEdit 10：用户可设置
          this.deviceWriteConditionList[index] = this.deviceParamsList.filter(
            (item) => (item.readWrite == 20 || item.readWrite == 30) && item.userEdit == 10
          );
          this.deviceWriteConditionList.splice(0, 0);
        }
      });
    },
    changeActionAttr(id,index) {
      this.actionValueList[index] = [];
      var dictItem=this.deviceParamsList.filter(
        (item) => item.id == id
      )[0];
      if (dictItem!=null&&dictItem['fanwei']!=null&&dictItem['fanwei'].indexOf(";")>0) {
        var fanwei=dictItem['fanwei'].split(";");
        var items=[];
        for (var i=0; i<fanwei.length; i++) {
          var item={};
          item.label=fanwei[i].split(":")[1];
          item.value=fanwei[i].split(":")[0];
          items.push(item);
        }
        this.actionValueList[index]=items;
      }
    },
    changeWriteConditionDevice(deviceId, index) {
      this.deviceWriteConditionList[index] = [];
      this.getDeviceParams(deviceId, index, 2);
    },
    addAction() {
      const key = "act_" + new Date().getTime();
      this.actions.push({
        deviceId: "",
        attr: "",
        value: "",
        key,
      });
      this.setFormValue(key + "_deviceId", "");
      this.setFormValue(key + "_attr", "");
      this.setFormValue(key + "_value", "");
    },
    deleteAction(index, item) {
      this.actions.splice(index, 1);
      const key = item.key;
      const deviceName = key + "_deviceId";
      const attrName = key + "_attr";
      const valueName = key + "_value";
      this.deleteFormValue(deviceName);
      this.deleteFormValue(attrName);
      this.deleteFormValue(valueName);
    },
    // 动态设置 form 的某个属性和值
    setFormValue(keyName, value) {
      this.$set(this.ruleForm, keyName, value);
    },
    // 动态删除 form 的某个属性和值
    deleteFormValue(keyName) {
      this.$delete(this.ruleForm, keyName);
    },
    // 可读写参数格式化中文名字
    fmtActionsAttrNickName(id) {
      for (let i = 0; i < this.deviceWriteConditionList.length; i++) {
        const item = this.deviceWriteConditionList[i] || [];
        for (let j = 0; j < item.length; j++) {
          if (item[j].id === id) {
            return item[j].name;
          }
        }
      }
    },
    // 格式化 触发动作数据
    fmtActions() {
      let obj = {};
      const formResult = this.ruleForm;
      const arr = Object.keys(formResult);
      arr.forEach((key) => {
        const value = formResult[key];
        if (key.indexOf("act_") > -1) {
          obj[key] = value;
        }
      });
      let items = [];
      for (let i = 0; i < this.actions.length; i++) {
        const item = this.actions[i];
        const device = obj[item.key + "_deviceId"];
        const attr = obj[item.key + "_attr"];
        const value = obj[item.key + "_value"];
        items.push({
          deviceId: device,
          id: attr,
          attr: this.fmtActionsAttrNickName(attr),
          value: value,
        });
      }
      return items;
    },
    submit() {
      if (this.ruleForm.id) {
        this.submitEdit()
      } else {
        this.submitAdd()
      }
    },
    submitAdd() {
      const actions = this.fmtActions();
      const conditions = null;
      this.request.addRule({
        actions,
        conditions,
        ruleType: 20,
        trigerTime: this.ruleForm.trigerTime,
        weekDays: this.ruleForm.weekDays,
        name: this.ruleForm.name,
        project: this.project,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$router.go(-1);
        }
      });
    },
    submitEdit() {
      const actions = this.fmtActions();
      const conditions = null;
      this.request.editRule({
        actions,
        conditions,
        ruleType: 20,
        trigerTime: this.ruleForm.trigerTime,
        weekDays: this.ruleForm.weekDays,
        name: this.ruleForm.name,
        id: this.ruleForm.id,
        project: this.project,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$router.go(-1);
        }
      });
    },
    // 编辑详情
    getDetailInfo(item) {
      this.ruleForm.id = item.id;
      this.ruleForm.name = item.name;
      this.ruleForm.trigerTime = item.trigerTime;
      this.ruleForm.weekDays = item.weekDays;
      const actions = JSON.parse(JSON.stringify(item.actions || []));
      for (let i = 0; i < actions.length; i++) {
        const key = new Date().getTime()+Math.round(Math.random() * 10000);
        actions[i]["key"] = "act_" + key;
        const deviceId = actions[i].deviceId;
        const attr = actions[i].id;
        const value = actions[i].value;
        this.setFormValue("act_" + key + "_deviceId", deviceId);
        const that=this;
        setTimeout(function() {
          that.changeWriteConditionDevice(deviceId, i);
          that.setFormValue("act_" + key + "_attr", attr*1);
        },1000);
        setTimeout(function() {
          that.changeActionAttr(attr,i);
          that.setFormValue("act_" + key + "_value", value);
        },2000);
      }
      this.actions = actions;
    },
  },
};
</script>
<style scoped lang="scss">
.form-body {
  width:800px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  box-sizing:border-box;
}
.container {
  margin: 18px 0;
  .el-form-item {
    margin-bottom: 0;
  }
}

.box-label {
  font-family: "microsoft yahei ui","microsoft yahei";
  font-size: 12px;
  color: #666666;
  margin-bottom: 8px;
}

.box-container {
  width: 700px;
  background: #f5f5f7;
  box-sizing: border-box;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  height: 360px;
  .box-scroll {
    height: 360px;
    overflow-y: auto;
    padding-right: 8px;
    box-sizing: border-box;
  }
  .box-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 4px;
    .el-form-item {
      margin-bottom: 0;
    }
    .blankDiv {
      width: 50px;
      color: #999999;
      font-size: 12px;
      text-align: center;
    }
    .group-title {
      color: #333333;
      font-size: 14px;
    }
    .label {
      width: 33%;
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      color: #999999;
      margin-bottom: 12px;
      text-align: center;
    }
    .value {
      width: 33%;
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      color: #333333;
      text-align: center;
      line-height: 28px;
    }
    .longlabel {
      width: 34%;
      text-align: left;
    }
    .longvalue {
      width: 34%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.add-btn {
  font-family: "microsoft yahei ui","microsoft yahei";
  font-size: 12px;
  text-align: left;
  color: #999999;
  cursor: pointer;
  line-height: 30px;
  width: 80px;
  text-align: center;
  &:hover {
    color: #3456ff;
  }
}

.el-icon-delete {
  cursor: pointer;
  &:hover {
    color: red;
  }
}

.submit-btn {
  display: flex;
  align-items: center;
  .submit-btn-item {
    width: 134px;
    height: 32px;
    border-radius: 2px;
    opacity: 1;
    background: #3456ff;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    &:nth-child(1) {
      color: #fff;
    }
    &:nth-child(2) {
      background-color: #fff;
    }
  }
}
</style>