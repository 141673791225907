<template>
  <div class="dilu-page">
    
    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-plus" type="primary" @click="goAdd">新增</el-button>
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-plus" type="primary" @click="adjust">校验</el-button>
      <el-select v-model="searchParams.homeFlag" placeholder="首页参数" style="width:120px;margin-left:10px;" @change="productDictionaryList" clearable>
        <el-option label="显示" :value=10 />
        <el-option label="不显示" :value=20 />
      </el-select>
      <el-select v-model="searchParams.faultFlag" placeholder="故障参数" style="width:120px;margin-left:10px;" @change="productDictionaryList" clearable>
        <el-option label="是" :value=10 />
        <el-option label="不是" :value=20 />
      </el-select>
      <el-select v-model="searchParams.readWrite" placeholder="读写属性" style="width:120px;margin-left:10px;" @change="productDictionaryList" clearable>
        <el-option label="可读" :value=10 />
        <el-option label="可写" :value=20 />
        <el-option label="可读可写" :value=30 />
      </el-select>
      <el-select v-model="searchParams.userEdit" placeholder="谁能设置" style="width:120px;margin-left:10px;" @change="productDictionaryList" clearable>
        <el-option label="用户可设置" :value=10 />
        <el-option label="工程可设置" :value=20 />
        <el-option label="都不可设置" :value=30 />
      </el-select>
      <el-select v-model="searchParams.userFlag" placeholder="用户查看" style="width:120px;margin-left:10px;" @change="productDictionaryList" clearable>
        <el-option label="允许" :value=10 />
        <el-option label="不允许" :value=20 />
      </el-select>
      <el-input v-model="searchParams.name" placeholder="字典名称" style="width:120px;margin-left:10px;" @keyup.enter.native="productDictionaryList"/>
      <el-input v-model="searchParams.groupName" placeholder="分组名称" style="width:120px;margin-left:10px;" @keyup.enter.native="productDictionaryList"/>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="name" label="数据名称" width="180" align="left"/>
      <el-table-column prop="chinese" label="中文显示" width="180" align="left"/>
      <el-table-column prop="readWrite" label="读写属性" width="90" align="left">
        <template slot-scope="scope">
          <span v-if="scope.row.readWrite==10">可读</span>
          <span v-if="scope.row.readWrite==20">可写</span>
          <span v-if="scope.row.readWrite==30">可读可写</span>
        </template>
      </el-table-column>
      <el-table-column prop="userEdit" label="谁可设置" width="120" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.userEdit==10">用户可设置</span>
          <span v-if="scope.row.userEdit==20">工程可设置</span>
          <span v-if="scope.row.userEdit==30">都不可设置</span>
        </template>
      </el-table-column>
      <el-table-column prop="userFlag" label="用户查看" width="90" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.userFlag==10">允许</span>
          <span v-if="scope.row.userFlag==20">不允许</span>
        </template>
      </el-table-column>
      <el-table-column prop="fanwei" label="数据范围" width="200" align="left"/>
      <el-table-column prop="homeFlag" label="首页参数" width="90" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.homeFlag==10">显示</span>
          <span v-if="scope.row.homeFlag==20">不显示</span>
        </template>
      </el-table-column>
      <el-table-column prop="chartFlag" label="数据曲线" width="90" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.chartFlag==10">显示</span>
          <span v-if="scope.row.chartFlag==20">不显示</span>
        </template>
      </el-table-column>
      <el-table-column prop="faultFlag" label="故障参数" width="90" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.faultFlag==10">是</span>
          <span v-if="scope.row.faultFlag==20">否</span>
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="数据单位" width="90" align="center"/>
      <el-table-column prop="groupName" label="分组名称" width="120" align="center"/>
      <el-table-column prop="position" label="显示顺序" width="90" align="center"/>
      <el-table-column prop="faultLevel" label="故障等级" width="90" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.faultLevel==10">轻微</span>
          <span v-if="scope.row.faultLevel==20">一般</span>
          <span v-if="scope.row.faultLevel==30">严重</span>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="创建时间" align="center" width="150" :formatter="formatDate"/>
      <el-table-column label="操作" align="center" width="120"  fixed="right">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="edit(scope.row)" type="text">编辑</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <AddDictionary :viewVisible="viewVisible" @viewClose="viewClose" :protocol="protocol" :currentData="currentData" @productDictionaryList="productDictionaryList" :isAdd="isAdd"/>

  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import AddDictionary from "./module/addDictionary";
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchParams: {
         protocol: 0,        // 产品协议
         name: "",           // 字典名称
         homeFlag: null,     // 首页参数
         faultFlag: null,    // 故障参数
         readWrite: null,    // 读写属性
         userEdit: null,     // 谁能设置
         userFlag: null,     // 用户查看
         groupName: null,    // 分组名称
         pageNum: 1,         // 页码
         pageSize: 20,       // 每页条数
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      isSearch: false,
      tableData: [],
      total: 0,
      viewVisible: false,
      currentData: {},
      isAdd: true,
      protocol: 0
    };
  },
  components: {
    DiluTable,
    AddDictionary
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.protocol=this.$route.query.protocol;
    this.productDictionaryList();
  },
  methods: {
    onInput() {
      this.$forceUpdate();
    },
    formatDate(row) {
       if (row.createdAt!=undefined&&row.createdAt.length>=10) {
         return row.createdAt.substring(0,10);
       } else {
         return "";
       }
    },
    //翻页
    currentChange(e) {
      this.searchParams.pageNum = e;
      this.productDictionaryList();
    },
    //搜索
    search() {
      this.isSearch = true; //是否在查询
      this.searchParams.pageNum = 1;
      this.total = 0;
      this.productDictionaryList();
    },
    //重置
    reset() {
      this.isSearch = false; //是否在查询
      this.searchParams.pageNum = 1;
      this.searchParams.pageSize = 20;
      this.total = 0;
      this.productDictionaryList();
    },
    //新增字典
    goAdd() {
      this.viewVisible=true;
      this.protocol=this.protocol;
    },
    //规范检查
    adjust() {
      this.request.protocolAdjust({"protocol":this.protocol}).then((res) => {
          if (res.code==0) {
            this.$message.success("检查完成");
            this.productDictionaryList();
          }
      });
    },
    //编辑
    edit(e) {
     this.isAdd = false;
     this.currentData = e;
     this.viewVisible = true;
     this.protocol = e.protocol;
    },
    //关闭新增编辑菜单弹窗
   viewClose() {
     this.viewVisible = false;
   },
   //查询列表
   productDictionaryList() {
    this.searchParams.protocol=this.protocol;
    this.request.productDictionaryList(this.searchParams).then((res) => {
        if (res.code==0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
    });
   },
  },
};
</script>

<style scoped>
</style>