<template>
   <div class="dilu-page">
    [售后服务] 开发中...
   </div>
 </template>
 
 
 <script>
 import { mapState } from "vuex";
 export default {
   data() {
     return {
       
     };
   },
   components: {
   
   },
   computed: {
     ...mapState(["buttonTextColor"]),
   },
   mounted() {
    
   },
   methods: {
     
   },
 };
 </script>
 
 <style scoped>
 .fixed-project {
    position: fixed; 
    top: 70px; 
    left: 420px;
    height: 32px;
    line-height: 32px;
    border-radius: 5px;
    color: black;
    text-align: left;
    font-size: small;
    z-index: 999;
  }
 </style>