<template>
  <div class="dilu-page">
    <div class="fixed-project">> {{ projectCurrentRowData.projectName }}</div>
    <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
      <el-table-column prop="userId" label="用户ID" align="center"/>
      <el-table-column prop="account" label="用户账户" align="center"/>
      <el-table-column prop="name" label="用户姓名" align="center"/>
      <el-table-column prop="orgName" label="组织名称" align="center"/>
      <el-table-column prop="status" label="在职状态" align="center" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 10">在职</span>
          <span v-if="scope.row.status == 20">离职</span>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="创建时间" align="center"/>
    </DiluTable>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DiluTable from "@/components/public/dilu-table.vue";
export default {
  data() {
    return {
      searchParams: {
        project: null,
        pageNum: 1, 
        pageSize: 12,
      },
      tableConfig: {
        isTree: false,
        isPage: true, 
      },
      total: 0,
      tableData: [],
    };
  },
  components: {
    DiluTable
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  mounted() {
    this.searchParams.project=this.projectCurrentRowData.projectCode;
     this.projectAccount();
  },
  methods: {
     projectAccount() {
      this.request.listAccount(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
      });
     },
     //翻页
     currentChange(e) {
        this.searchParams.pageNum = e;
        this.projectAccount();
     },
  },
};
</script>

<style scoped>
.fixed-project {
  position: fixed; 
  top: 70px; 
  left: 420px;
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
  color: black;
  text-align: left;
  font-size: small;
  z-index: 999;
}
</style>